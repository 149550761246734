import React, { useState } from "react";
import { Card } from "reactstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { Block, BlockBetween, BlockTitle, UserAvatar } from "../../Component";
import BlockUpdateButton from "../../button/BlockUpdateButton";
import UpdatableText from "../../shared/UpdatabaleText";

import { DATE_FORMATS } from "../../../utilities/constants";
import { formatDate } from "../../../utilities/dateTimeUtils";
import { studentService } from "../../../services/studentService";
import { appendToFormData } from "../../../utilities/formDataUtils";
import { useBeManualFetcher } from "../../../hooks/useBeManualFetcher";
import { useStudentPageContext } from "../../../hooks/useStudentPageContext";
import EventEmitter, { EVENT_TOPICS } from "../../../utilities/eventUtils";

const StudentInfoMainBlock = () => {
  const { id } = useParams();
  const { student } = useStudentPageContext();
  const { register, handleSubmit } = useForm();
  const [onFetch] = useBeManualFetcher();

  const [hasEditClicked, setHasEditClicked] = useState(false);

  function handleOnEditClicked() {
    setHasEditClicked((prev) => !prev);
  }

  async function onFormSubmit(form) {
    const {
      firstName,
      lastName,
      middleName,
      dateOfBirth,
      phoneNumber,
      email,
      passportSeriesAndNumber,
    } = form;

    console.log(form);
    let newFormData = new FormData();

    appendToFormData(newFormData, {
      firstName,
      lastName,
      middleName,
      dateOfBirth: new Date(dateOfBirth).toISOString(),
      phoneNumber,
      email,
      passportSeriesAndNumber,
    });

    return await onFetch({
      action: () => studentService.updateStudent(id, newFormData),
      onLoad: (response) => {
        EventEmitter.emit(EVENT_TOPICS.ON_STUDENT_UPDATED, { response });
        handleOnEditClicked();
      },
      successMessage: "Student fields updated successfully",
    });
  }

  return (
    <Card className="card-inner">
      <Block>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <BlockBetween>
            <BlockTitle tag="h5">Personal Information</BlockTitle>
            <BlockUpdateButton
              hasEditClicked={hasEditClicked}
              handleOnEditClicked={handleOnEditClicked}
            />
          </BlockBetween>
          <div className="profile-ud-list">
            <div className="profile-ud-item d-flex align-items-center w-15">
              <div className="profile-ud wider">
                <UserAvatar
                  className="xl"
                  text={`${
                    student?.firstName ? student?.firstName?.charAt(0) : ""
                  }${student?.lastName ? student?.lastName?.charAt(0) : ""}`}
                  image={student?.photoUrl}
                  imageAlt={student?.firstName}
                  theme={"lighter"}
                />
              </div>
            </div>
            <div className="profile-ud-item position-relative">
              <UpdatableText
                title="First Name"
                defaultValue={student?.firstName}
                name={"firstName"}
                register={register}
                onEditClicked={hasEditClicked}
                isWider
              />
              <UpdatableText
                title="Last Name"
                defaultValue={student?.lastName}
                name={"lastName"}
                register={register}
                onEditClicked={hasEditClicked}
                isWider
              />
              <UpdatableText
                title="Middle Name"
                defaultValue={student?.middleName}
                name={"middleName"}
                register={register}
                onEditClicked={hasEditClicked}
                isWider
              />
              <UpdatableText
                title="Date of Birth"
                defaultValue={formatDate(
                  new Date(student?.dateOfBirth),
                  DATE_FORMATS.DATE_ONLY
                )}
                name={"dateOfBirth"}
                type={"date"}
                register={register}
                onEditClicked={hasEditClicked}
                isWider
              />
              <UpdatableText
                title="Source of Information"
                defaultValue={student?.hearAboutUs}
                name={"hearAboutUs"}
                register={register}
                isWider
              />
            </div>
            <div className="profile-ud-item">
              <UpdatableText
                title="Phone Number"
                defaultValue={student?.phoneNumber}
                name={"phoneNumber"}
                register={register}
                onEditClicked={hasEditClicked}
                isWider
              />
              <UpdatableText
                title="Email Address"
                defaultValue={student?.email}
                name={"email"}
                register={register}
                onEditClicked={hasEditClicked}
                isWider
              />
              <UpdatableText
                title="Passport Number"
                defaultValue={student?.passportSeriesAndNumber}
                name={"passportSeriesAndNumber"}
                register={register}
                onEditClicked={hasEditClicked}
                isWider
              />
            </div>
          </div>
        </form>
      </Block>
    </Card>
  );
};

export default StudentInfoMainBlock;
