import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useStudentPageContext } from "../../hooks/useStudentPageContext";
import { studentService } from "../../services/studentService";
import { STUDENT_STATUSES } from "../../utilities/constants";
import EventEmitter, { EVENT_TOPICS } from "../../utilities/eventUtils";
import { Button, Col, Row } from "../Component";
import FormWithLabel from "../input/label/FormWithLabel";

const DocumentVerificationModal = ({
  showModal,
  onClose,
  onConfirm,
  isLoading,
}) => {
  const { id } = useParams();
  const { handleSubmit, register } = useForm();
  const { student } = useStudentPageContext();

  async function handleStudentApplicationStatusUpdate(status, body) {
    return await studentService
      .updateStudentApplicationStatus(id, status, body)
      .then((response) => {
        EventEmitter.emit(EVENT_TOPICS.ON_STUDENT_UPDATED, { response });
      });
  }

  async function onFormSubmit(form) {
    const { photo, passport, certificate, documents } = form;

    let body = {
      isPhotoVerified: photo,
      isPassportVerified: passport,
      isIeltsCertificateVerified: certificate,
      isSecondaryEducationCertificateVerified: documents,
    };

    return await handleStudentApplicationStatusUpdate(
      STUDENT_STATUSES.DOCUMENT_VERIFICATION,
      body
    ).then(() => onClose());
  }

  return (
    <Modal size="sm" isOpen={showModal}>
      <ModalHeader
        toggle={onClose}
        close={<Button className="close" onClick={onClose} iconName="cross" />}
      >
        Document Verification
      </ModalHeader>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalBody>
          <Row className="g-2">
            <Col size="12">
              <FormWithLabel
                label="Photo 3x4.5"
                className="d-flex justify-content-between"
              >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input custom-control-lg"
                    id="photo"
                    name="photo"
                    defaultChecked={student?.documentVerified?.isPhotoVerified}
                    ref={register()}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="photo"
                  ></label>
                </div>
              </FormWithLabel>
              <FormWithLabel
                label="Passport"
                className="d-flex justify-content-between"
              >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input custom-control-lg"
                    id="passport"
                    name="passport"
                    ref={register()}
                    defaultChecked={
                      student?.documentVerified?.isPassportVerified
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="passport"
                  ></label>
                </div>
              </FormWithLabel>
              <FormWithLabel
                label="English Certificate"
                className="d-flex justify-content-between"
              >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input custom-control-lg"
                    id="certificate"
                    name="certificate"
                    defaultChecked={
                      student?.documentVerified?.isIeltsCertificateVerified
                    }
                    ref={register()}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="certificate"
                  ></label>
                </div>
              </FormWithLabel>
              <FormWithLabel
                label="Other Documents"
                className="d-flex justify-content-between"
              >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input custom-control-lg"
                    id="documents"
                    name="documents"
                    ref={register()}
                    defaultChecked={
                      student?.documentVerified
                        ?.isSecondaryEducationCertificateVerified
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="documents"
                  ></label>
                </div>
              </FormWithLabel>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button
            color="white border-light"
            onClick={onClose}
            title={"Close"}
          />
          <Button
            color={"success"}
            onClick={onConfirm}
            title={"Submit"}
            disabled={isLoading}
            isLoading={isLoading}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default DocumentVerificationModal;
