import backendHttpRequest from "./backendHttpRequest";
import { config } from "../configs/repositoryConfig";
import { convertToQueryString } from "../utilities/httpUtils";
import { replaceAllStringByKeys } from "../utilities/stringUtils";

export const getStudents = async (filteredObject) => {
  const queryString = convertToQueryString(filteredObject);
  const resourceObject = {
    ...config.backendApiSettings.resources.getStudents,
  };
  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = `${endpoint}${queryString ? `?${queryString}` : ""
    }`;

  const apiUsers = await backendHttpRequest({ resourceObject });
  return apiUsers;
};

export const getStudentById = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.getStudentById,
  };
  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":id"],
    [id]
  );

  const apiUser = await backendHttpRequest({ resourceObject });

  return apiUser;
};

export async function updateStudent(id, body) {
  const resourceObject = {
    ...config.backendApiSettings.resources.updateStudent,
  };

  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":id"],
    [id]
  );

  const updatedUser = await backendHttpRequest({
    resourceObject,
    body,
    isFormData: true,
  });

  return updatedUser;
}

export const uploadStudentFiles = async (id, formData) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.uploadStudentFiles,
  };

  const updatedUser = await backendHttpRequest({
    resourceObject,
    body: formData,
    isFormData: true,
  });

  return updatedUser;
}

export const updateStudentStatus = async (status, body) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.updateStudentStatus,
  };

  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":status"],
    [status]
  );

  const updatedUser = await backendHttpRequest({
    resourceObject,
    body,
  });

  return updatedUser;
};

export async function updateStudentApplicationStatus(id, status, body) {
  const resourceObject = {
    ...config.backendApiSettings.resources.updateStudentApplicationStatus,
  };

  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":id", ":status"],
    [id, status]
  );

  const updatedUser = await backendHttpRequest({
    resourceObject,
    body,
  });

  return updatedUser;
}

export const removeStudent = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.deleteStudent,
  };
  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = endpoint.replace(":id", id);

  await backendHttpRequest({ resourceObject });

  return id;
};

export const exportStudent = async (body) => {
  const url = "https://back.pharmagency.uz/api/v1/students/migrate";

  await backendHttpRequest({
    url,
    restHeaders: { "X-API-KEY": config["X-API-KEY"] },
    resourceObject: {
      method: "POST",
    },
    body,
  });
};
