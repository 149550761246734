import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import { DataTableItem, UserAvatar, Icon, DataTableRow } from "../Component";
import ConfirmationModal from "../modals/ConfirmationModal";
import Badge from "../shared/Badge";

import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import { studentService } from "../../services/studentService";
import {
  DATE_FORMATS,
  FACULTIES_ENUM,
  FACULTIES_ENUM_READABLE,
  SCHOLARSHIP_TYPES_ENUM,
  SCHOLARSHIP_TYPES_ENUM_READABLE,
  STUDENT_READABLE_STATUSES,
  STUDENT_STATUSES,
} from "../../utilities/constants";
import { formatDate } from "../../utilities/dateTimeUtils";
import EventEmitter, { EVENT_TOPICS } from "../../utilities/eventUtils";

const StudentsPageTableBodyItems = ({ item, onSelectChange }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [onFetch] = useBeManualFetcher();

  const handleDeleteStudentClick = () => setShowDeleteModal(true);

  const handleStudentDelete = async (id) => {
    await onFetch({
      action: () => studentService.removeStudent(id),
      onLoad: () => {
        EventEmitter.emit(EVENT_TOPICS.ON_STUDENT_DELETED, {
          deletedStudentId: id,
        });

        setShowDeleteModal(false);
      },
      successMessage: "Student successfully deleted",
    });
  };

  function facultyPainter(facultyType) {
    const color =
      facultyType === FACULTIES_ENUM.FOUNDATION_CERTIFICATE_BIOSCIENCES
        ? "success"
        : facultyType ===
          FACULTIES_ENUM.FOUNDATION_CERTIFICATE_PHARMACEUTICAL_SCIENCE
        ? "info"
        : facultyType === FACULTIES_ENUM.FOUNDATION_CERTIFICATE_SCIENCES
        ? "purple"
        : facultyType === FACULTIES_ENUM.PRE_FOUNDATION
        ? "orange"
        : "warning";

    return (
      <span className={`tb-status text-${color}`}>
        {FACULTIES_ENUM_READABLE[facultyType]}
      </span>
    );
  }

  function feeTypePainter(feeType) {
    const color =
      feeType === SCHOLARSHIP_TYPES_ENUM.SCHOLARSHIP ? "success" : "warning";

    return (
      <span className={`tb-status text-${color}`}>
        {SCHOLARSHIP_TYPES_ENUM_READABLE[feeType]}
      </span>
    );
  }

  function handleDisplayPreUniversityCourse(courses) {
    const COURSE_SHORTCUTS = {
      GENERAL_ENGLISH: "GE",
      GENERAL_CHEMISTRY: "GC",
      IELTS_PREPARATION: "IP",
      BIOLOGY: "B",
    };

    return (
      <strong>
        {courses?.length > 0
          ? courses?.map((course, index) =>
              courses?.length - 1 !== index
                ? COURSE_SHORTCUTS[course] + ", "
                : COURSE_SHORTCUTS[course]
            )
          : "-"}
      </strong>
    );
  }

  function appStatusPainter(status) {
    const applicationStatusColors = {
      SUBMITTED_APPLICATION: "info",
      DOCUMENT_VERIFICATION: "danger",
      OFFER_SENT_TO_APPLICATION: "warning",
      APPLICATION_CONDITIONAL_OFFER: "orange",
      REQUESTED_FOR_ADDITIONAL_DOCUMENTS: "warning",
      APPLICATION_UNCONDITIONAL_OFFER: "purple",
      TUITION_FEE_PAID: "dark",
      ENROLLED: "success",
      REJECTED: "gray",
    };

    return (
      <Badge
        color={applicationStatusColors?.[status]}
        text={STUDENT_READABLE_STATUSES[status]}
      />
    );
  }

  return (
    <DataTableItem>
      <DataTableRow className="nk-tb-col-check" noText>
        <div className="custom-control custom-control-sm custom-checkbox notext">
          <input
            type="checkbox"
            className="custom-control-input form-control"
            defaultChecked={item?.check}
            id={item.id + "pid-all"}
            key={Math.random()}
            onChange={(e) => onSelectChange(e, item.id)}
          />
          <label
            className="custom-control-label"
            htmlFor={item.id + "pid-all"}
          ></label>
        </div>
      </DataTableRow>
      <DataTableRow noText>
        <Link
          to={`${process.env.PUBLIC_URL}/students/${item.id}?status=${
            item?.currentStatus?.status
          }${
            item?.currentStatus?.status === STUDENT_STATUSES.REJECTED
              ? `&cancelledStatus=${
                  item?.statusHistories?.[item?.statusHistories?.length - 1]
                    ?.status
                }`
              : ""
          } `}
          state={item}
        >
          <div className="user-card">
            <UserAvatar
              className="xs"
              text={
                item?.firstName?.charAt(0).toUpperCase() +
                item?.lastName?.charAt(0).toUpperCase()
              }
            ></UserAvatar>
            <div className="user-info">
              <span className="tb-lead">
                {item.firstName} {item.lastName}
              </span>
            </div>
          </div>
        </Link>
      </DataTableRow>
      <DataTableRow
        size="sm"
        text={formatDate(item?.createdAt, DATE_FORMATS.DATE_REGISTERED)}
      />
      <DataTableRow noText>{facultyPainter(item?.facultyType)}</DataTableRow>
      <DataTableRow noText>
        {item?.feeType
          ? feeTypePainter(item?.feeType)
          : handleDisplayPreUniversityCourse(item?.preUniversityCourseType)}
      </DataTableRow>
      <DataTableRow noText>
        {appStatusPainter(item?.currentStatus?.status)}
      </DataTableRow>
      <DataTableRow className="nk-tb-col-tools" noText>
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#delete"
                      onClick={handleDeleteStudentClick}
                    >
                      <Icon name="trash"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </DataTableRow>
      <ConfirmationModal
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => handleStudentDelete(item?.id)}
        confirmColor="danger"
        confirmTitle="Delete"
      />
    </DataTableItem>
  );
};

export default StudentsPageTableBodyItems;
