import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";

import {
  getNews,
  getNewsById,
  createNews,
  updateNews,
  removeNews,
  updateNewsStatus,
  linkNewsToCategories,
  changeNewsSequence,
  uploadNewsFiles,
} from "../repositories/newsRepository";

export default {
  getNews: async ({ currentPage, pageSize }) => {
    const pageNumber =
      isUndefined(currentPage) || isNull(currentPage) ? 1 : currentPage;
    const itemsPerPage =
      isUndefined(pageSize) || isNull(pageSize) ? 20 : pageSize;

    return await getNews({
      "current-page": pageNumber,
      "page-size": itemsPerPage,
    });
  },

  getNewsById: async (id) => await getNewsById(id),

  createNews: async (body) => await createNews(body),

  uploadNewsFiles: async (formData) => await uploadNewsFiles(formData),

  updateNews: async (id, body) => await updateNews(id, body),

  removeNews: async (id) => await removeNews(id),

  updateNewsStatus: async (id, status, body) =>
    await updateNewsStatus(id, status, body),

  linkNewsToCategories: async (id, type, body) =>
    await linkNewsToCategories(id, type, body),

  changeNewsSequence: async (id, sequenceNumber) =>
    await changeNewsSequence(id, Number(sequenceNumber)),
};
