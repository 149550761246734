import backendHttpRequest from "./backendHttpRequest";
import { config } from "../configs/repositoryConfig";
import { convertToQueryString } from "../utilities/httpUtils";
import { replaceAllStringByKeys } from "../utilities/stringUtils";

export const getOlympiadParticipants = async (filteredObject) => {
  const queryString = convertToQueryString(filteredObject);
  const resourceObject = {
    ...config.backendApiSettings.resources.getOlympiadParticipants,
  };
  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = `${endpoint}${queryString ? `?${queryString}` : ""
    }`;

  const apiUsers = await backendHttpRequest({ resourceObject });
  return apiUsers;
};

export const getOlympiadParticipantById = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.getOlympiadParticipantById,
  };
  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":id"],
    [id]
  );

  const apiUser = await backendHttpRequest({ resourceObject });

  return apiUser;
};

export const updateOlympiadParticipantStatus = async (id, status) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.updateOlympiadParticipantStatus,
  };
  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = `${endpoint}?${id}/${status}`;

  const apiUser = await backendHttpRequest({ resourceObject });

  return apiUser;
};

export const removeOlympiadParticipant = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.deleteOlympiadParticipant,
  };
  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = endpoint.replace(":id", id);

  await backendHttpRequest({ resourceObject });

  return id;
};