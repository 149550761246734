import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import * as XLSX from "xlsx";

import MainPageHOC from "../../layout/hoc/MainPageHOC";
import MainPageTableHeader from "../../layout/body/MainPageTableHeader";

import PaginationComponent from "../../components/pagination/PaginationComponent";
import FetcherComponent from "../../components/shared/FetcherComponent";
import MainPageHeader from "../../components/shared/MainPageHeader";

import { useOlympiadPageContext } from "../../hooks/useOlympiadPageContext";
import OlympiadPageTableBody from "../../components/olympiadPage/OlympiadPageTableBody";
import { olympiadService } from "../../services/olympiadService";

const OlympiadPage = () => {
  const {
    applicants,
    setApplicants,
    currentPage,
    setCurrentPage,
    totalPages,
    setTotalPages,
    totalRecords,
    setTotalRecords,
    setPageSize,
    pageSize,
  } = useOlympiadPageContext();

  const [searchParams] = useSearchParams();
  const currentPageQueryString = searchParams.get("current-page");
  const pageSizeQueryString = searchParams.get("page-size");

  const fetchUsers = useCallback(
    async () =>
      await olympiadService.getOlympiadParticipants({
        currentPage: currentPageQueryString,
        pageSize: pageSizeQueryString,
      }),
    [currentPageQueryString, pageSizeQueryString]
  );

  const handleOnLoadFetchedUsers = useCallback(
    (res) => {
      setApplicants(res?.content);
      setCurrentPage(res?.number + 1);
      setTotalPages(res?.totalPages);
      setTotalRecords(res?.totalElements);
      setPageSize(res?.size);
    },
    [setApplicants, setCurrentPage, setTotalPages, setTotalRecords, setPageSize]
  );

  const participantDataChecker = useCallback(
    () => (applicants?.length > 0 ? true : false),
    [applicants]
  );

  const onExportClick = async () => {
    const copiedApplicants = [...applicants];
    const applicantsToExport = copiedApplicants?.filter(
      (applicant) => applicant?.check === true
    );
    const newWorkBook = XLSX.utils.book_new();
    const newWorkSheet = XLSX.utils.json_to_sheet(applicantsToExport);
    

    XLSX.utils.book_append_sheet(newWorkBook, newWorkSheet, "NewSheet1");
    XLSX.writeFile(newWorkBook, "NewFile.xlsx");
  };

  return (
    <MainPageHOC
      headerTitle={"Olympiad Participants"}
      pageHeader={
        <MainPageHeader
          totalRecords={totalRecords}
          pageName="Olympiad Participants"
          redirectTo={"add"}
          disableExportButton={applicants?.find(
            (applicant) => applicant?.check === true
          )}
          showExportButton={true}
          onExportClick={onExportClick}
        />
      }
      tableHeader={
        <MainPageTableHeader pageSize={pageSize} setPageSize={setPageSize} />
      }
      tableBody={
        <FetcherComponent action={fetchUsers} onLoad={handleOnLoadFetchedUsers}>
          <>
            <OlympiadPageTableBody users={applicants} setUsers={setApplicants} />
            <div className="card-inner">
              <PaginationComponent
                currentPageNumber={currentPage}
                totalPages={totalPages}
                totalRecords={totalRecords}
                status={pageSizeQueryString}
              />
            </div>
          </>
        </FetcherComponent>
      }
      hasDataForRendering={participantDataChecker}
    />
  );
};
export default OlympiadPage;
