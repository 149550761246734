import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DATE_FORMATS,
  IMAGE_KEYS,
  LANGUAGE_KEYS,
} from "../../utilities/constants";
import { unixDateConvertor } from "../../utilities/dateTimeUtils";
import {
  UserAvatar,
  DataTableRow,
  Icon,
  InputSwitch,
} from "../Component";
import ConfirmationModal from "../modals/ConfirmationModal";
import DraggableItemHOC from "../shared/DraggableItemHOC";

import newsService from "../../services/newsService";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import EventEmitter, { EVENT_TOPICS } from "../../utilities/eventUtils";

function NewsPageTableBodyItem({ id, index, item }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [onFetch] = useBeManualFetcher();

  const handleDeleteClick = () => setShowDeleteModal(true);

  const handleNewsDelete = async (id) => {
    await onFetch({
      action: () => newsService.removeNews(id),
      onLoad: () => {
        EventEmitter.emit(EVENT_TOPICS.ON_NEWS_DELETED, {
          deletedNewsId: id,
        });

        setShowDeleteModal(false);
      },
      successMessage: "News successfully deleted",
    });
  };

  return (
    <DraggableItemHOC id={id} index={index}>
      <DataTableRow noText>{item?.sequenceNumber}</DataTableRow>
      <DataTableRow noText>
        <Link to={`${process.env.PUBLIC_URL}/news/${item.id}`} state={item}>
          <div className="user-card">
            <UserAvatar
              className="lg rectangle"
              text={"NA"}
              image={
                item?.imageUrls?.[LANGUAGE_KEYS.EN]?.[
                  IMAGE_KEYS.COVER_IMAGE_GET
                ]
              }
              imageAlt={item?.title?.[LANGUAGE_KEYS.EN]}
            ></UserAvatar>
            <div className="user-info">
              <span className="tb-lead">{item?.title?.[LANGUAGE_KEYS.EN]}</span>
            </div>
          </div>
        </Link>
      </DataTableRow>
      <DataTableRow
        size="sm"
        text={unixDateConvertor(item?.createdAt, DATE_FORMATS.DATE_REGISTERED)}
      />
      <DataTableRow noText>
        <InputSwitch checked={item?.latestNews?.isLatestNews} />
      </DataTableRow>
      <DataTableRow noText>
        <InputSwitch checked={item?.isAtTop} />
      </DataTableRow>
      
      <DataTableRow className="nk-tb-col-tools" noText>
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#delete"
                      onClick={handleDeleteClick}
                    >
                      <Icon name="trash"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </DataTableRow>
      <ConfirmationModal
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => handleNewsDelete(item?.id)}
        confirmColor="danger"
        confirmTitle="Delete"
      />
    </DraggableItemHOC>
  );
}

export default NewsPageTableBodyItem;
