import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DetailsPageHeader from "../../components/shared/DetailsPageHeader";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import StudentInfoMainBlock from "../../components/studentPage/studentDetailsPage/StudentInfoMainBlock";
import StudentDocumentsBlock from "../../components/studentPage/studentDetailsPage/StudentDocumentsBlock";
import StudentInfoEducationBlock from "../../components/studentPage/studentDetailsPage/StudentInfoEducationBlock";
import StudentAddressInfoBlock from "../../components/studentPage/studentDetailsPage/StudentAddressInfoBlock";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import DocumentVerificationModal from "../../components/modals/DocumentVerificationModal";

import { useStudentPageContext } from "../../hooks/useStudentPageContext";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import { studentService } from "../../services/studentService";
import StudentStepperComponent from "../../components/studentPage/studentDetailsPage/StudentStepperComponent";
import { convertValuesToArray } from "../../utilities/objectUtils";
import {
  FACULTIES_ENUM,
  STUDENT_STATUSES,
  STUDENT_STEPPER_READABLE_STATUSES,
} from "../../utilities/constants";
import { useState } from "react";

import moment from "moment";

const StudentDetailsPage = () => {
  const { id } = useParams();
  const { student, setStudent } = useStudentPageContext();
  const [onFetch, isLoading] = useBeManualFetcher();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState({
    isOpen: false,
  });

  async function handleStudentDataFetch() {
    await onFetch({
      action: () => studentService.getStudentById(id),
      onLoad: (response) => setStudent(response),
    });
  }

  useEffect(() => {
    handleStudentDataFetch();
  }, [id]);

  const handleStatusQueryChange = (status, statusTitle = "status") => {
    searchParams.set(statusTitle, status);
    setSearchParams(searchParams, { replace: true });
  };

  const handleStatusChange = async ({
    status,
    id = student?.id,
    buttonTitle,
  }) => {
    return await onFetch({
      action: () => studentService.updateStudentApplicationStatus(id, status),
      onLoad: (result) => {
        if (status === STUDENT_STATUSES.REJECTED) {
          handleStatusQueryChange(
            searchParams.get("status"),
            "cancelledStatus"
          );
        }

        handleStatusQueryChange(status);
        setStudent(result);
      },
      successMessage: "Student status updated successfully",
      fetchingItemId: buttonTitle ?? id,
    });
  };

  const handleStudentExport = async () => {
    const exportBody = {
      ...student,
      dateOfBirth: moment(student.dateOfBirth).valueOf(),
    };

    console.log(student);

    return await onFetch({
      action: () => studentService.exportStudent(exportBody),
      successMessage: "Student exported to SRMS system",
    });
  };

  const buttonsRenderer = {
    [STUDENT_STATUSES.SUBMITTED_APPLICATION]: [
      {
        onClick: () =>
          handleStatusChange({
            status: STUDENT_STATUSES.REJECTED,
            buttonTitle: "Reject",
          }),
        color: "danger",
        icon: "cross",
        title: "Reject",
        className: "mr-2",
      },
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Request Documents",
            status: STUDENT_STATUSES.DOCUMENT_VERIFICATION,
          }),
        color: "info",
        icon: "list-thumb",
        title: "Request Documents",
      },
    ],
    [STUDENT_STATUSES.DOCUMENT_VERIFICATION]: [
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Reject",
            status: STUDENT_STATUSES.REJECTED,
          }),
        color: "danger",
        icon: "cross",
        title: "Reject",
        className: "mr-2",
      },
      {
        onClick: () => setShowVerificationModal(true),
        color: "warning",
        icon: "list-check",
        title: "Verify Documents",
        className: "mr-2",
      },
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Send Offer to Apply",
            status: STUDENT_STATUSES.OFFER_SENT_TO_APPLICATION,
          }),
        color: "success",
        icon: "send",
        title: "Send Offer to Apply",
      },
    ],
    [STUDENT_STATUSES.OFFER_SENT_TO_APPLICATION]: [
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Reject",
            status: STUDENT_STATUSES.REJECTED,
          }),
        color: "danger",
        icon: "cross",
        title: "Reject",
        className: "mr-2",
      },
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Conditional Offer",
            status: STUDENT_STATUSES.APPLICATION_CONDITIONAL_OFFER,
          }),
        color: "info",
        icon: "file-minus",
        title: "Conditional Offer",
        className: "mr-2",
      },
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Unconditional Offer",
            status: STUDENT_STATUSES.APPLICATION_UNCONDITIONAL_OFFER,
          }),
        color: "success",
        icon: "file-check",
        title: "Unconditional Offer",
      },
    ],
    [STUDENT_STATUSES.APPLICATION_CONDITIONAL_OFFER]: [
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Reject",
            status: STUDENT_STATUSES.REJECTED,
          }),
        color: "danger",
        icon: "cross",
        title: "Reject",
        className: "mr-2",
      },
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Request For Documents",
            status: STUDENT_STATUSES.REQUESTED_FOR_DOCUMENTS,
          }),
        color: "warning",
        icon: "send",
        title: "Request For Documents",
        className: "mr-2",
      },
    ],
    [STUDENT_STATUSES.REQUESTED_FOR_DOCUMENTS]: [
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Reject",
            status: STUDENT_STATUSES.REJECTED,
          }),
        color: "danger",
        icon: "cross",
        title: "Reject",
        className: "mr-2",
      },
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Unconditional Offer",
            status: STUDENT_STATUSES.APPLICATION_UNCONDITIONAL_OFFER,
          }),
        color: "success",
        icon: "file-check",
        title: "Unconditional Offer",
      },
    ],
    [STUDENT_STATUSES.APPLICATION_UNCONDITIONAL_OFFER]: [
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Reject",
            status: STUDENT_STATUSES.REJECTED,
          }),
        color: "danger",
        icon: "cross",
        title: "Reject",
        className: "mr-2",
      },
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Confirm Tuition Fee Payment",
            status: STUDENT_STATUSES.TUITION_FEE_PAID,
          }),
        color: "success",
        icon: "report-profit",
        title: "Confirm Tuition Fee Payment",
      },
    ],
    [STUDENT_STATUSES.TUITION_FEE_PAID]: [
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Reject",
            status: STUDENT_STATUSES.REJECTED,
          }),
        color: "danger",
        icon: "cross",
        title: "Reject",
        className: "mr-2",
      },
      {
        onClick: () =>
          handleStatusChange({
            buttonTitle: "Enrolled",
            status: STUDENT_STATUSES.ENROLLED,
          }),
        color: "success",
        icon: "check-circle",
        title: "Enrolled",
      },
    ],
    [STUDENT_STATUSES.ENROLLED]: [
      {
        onClick: async () => await handleStudentExport(),
        color: "info",
        icon: "user-add",
        title: "Export to SRMS",
        className: "mr-2",
        hideCondition: student?.facultyType === FACULTIES_ENUM.PRE_FOUNDATION,
      },
    ],
    [STUDENT_STATUSES.REJECTED]: [
      {
        onClick: () => {
          handleStatusChange({
            buttonTitle: "Resume",
            status: searchParams.get("cancelledStatus"),
          });
          searchParams.delete("cancelledStatus");
        },
        color: "warning",
        icon: "play",
        title: "Resume",
      },
    ],
  };

  return (
    <>
      <Head title="Employee Details" />
      <Content>
        <DetailsPageHeader
          title={"Student -"}
          name={`${student?.firstName} ${student?.lastName} ${student?.middleName}`}
          createdDate={student?.createdAt}
          faculty={student?.facultyType}
          scholarship={student?.feeType}
          goBackPath={"/students"}
          isLoading={isLoading.id}
          buttonsToRender={buttonsRenderer[student?.currentStatus?.status]}
        />
        <StudentStepperComponent
          steps={convertValuesToArray(STUDENT_STEPPER_READABLE_STATUSES)}
          onClick={(status) =>
            setShowConfirmationModal({ isOpen: true, status: status })
          }
        />
        <StudentInfoMainBlock />
        <StudentAddressInfoBlock />
        <StudentInfoEducationBlock />
        {student?.facultyType !== "PRE_UNIVERSITY" ? (
          <StudentDocumentsBlock />
        ) : null}
        <ConfirmationModal
          showModal={showConfirmationModal.isOpen}
          onClose={() => setShowConfirmationModal({ isOpen: false })}
          onConfirm={() => {
            handleStatusChange({ status: showConfirmationModal.status });
            setShowConfirmationModal({ isOpen: false });
          }}
          modalTitle={"Do you really want to step back?"}
          isLoading={isLoading}
        />
        <DocumentVerificationModal
          showModal={showVerificationModal}
          onClose={() => setShowVerificationModal(false)}
          isLoading={isLoading}
        />
      </Content>
    </>
  );
};

export default StudentDetailsPage;
