import React from "react";

import {
  DataTableBody,
  DataTableHead,
  DataTableRow,
} from "../../components/Component";
import NewsPageTableBodyItem from "./NewsPageTableBodyItem";

import { useNewsPageContext } from "../../hooks/useNewsPageContext";
import DraggableListHOC from "../shared/DraggableListHOC";
import { EVENT_TOPICS } from "../../utilities/eventUtils";
import newsService from "../../services/newsService";

const NewsPageTableBody = () => {
  const { news } = useNewsPageContext();

  return (
    <DraggableListHOC
      data={news}
      action={async ({ draggableId, sequenceNumber }) =>
        await newsService.changeNewsSequence(draggableId, sequenceNumber)
      }
      eventTopic={EVENT_TOPICS.ON_NEWS_SEQUENCE_CHANGED}
    >
      <DataTableBody>
        <DataTableHead>
          <DataTableRow text="No" />
          <DataTableRow text="Title" />
          <DataTableRow text="Registered Date" />
          <DataTableRow text="Latest News" />
          <DataTableRow text="Top Stories" />
          <DataTableRow className="nk-tb-col-tools" noText />
        </DataTableHead>
        {news?.map((item, index) => (
          <NewsPageTableBodyItem
            key={item.id}
            id={item.id}
            index={index}
            item={item}
          />
        ))}
      </DataTableBody>
    </DraggableListHOC>
  );
};

export default NewsPageTableBody;
