import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import DetailsPageHeader from "../../components/shared/DetailsPageHeader";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import { useOlympiadPageContext } from "../../hooks/useOlympiadPageContext";
import { Card } from "reactstrap";
import { Block, BlockBetween, BlockTitle } from "../../components/Component";
import UpdatableText from "../../components/shared/UpdatabaleText";
import { olympiadService } from "../../services/olympiadService";

const OlimpiadParticipantDetailsPage = () => {
  const { id } = useParams();
  const { applicant, setApplicant } = useOlympiadPageContext();
  const [onFetch, isLoading] = useBeManualFetcher();

  async function handleStudentDataFetch() {
    await onFetch({
      action: () => olympiadService.getOlympiadParticipantById(id),
      onLoad: (response) => setApplicant(response),
    });
  }

  useEffect(() => {
    handleStudentDataFetch();
  }, [id]);

  console.log(applicant);

  return (
    <>
      <Head title="Employee Details" />
      <Content>
        <DetailsPageHeader
          title={"Participant -"}
          name={`${applicant?.firstName} ${applicant?.lastName} ${applicant?.middleName}`}
          createdDate={applicant?.createdAt}
          goBackPath={"/olympiad-participants"}
          isLoading={isLoading.id}
        />
        <Card className="card-inner">
          <Block>
            <form>
              <BlockBetween className="mb-3">
                <BlockTitle tag="h5">Personal Information</BlockTitle>
              </BlockBetween>
              <div className="profile-ud-list">
                <div className="profile-ud-item position-relative">
                  <UpdatableText
                    title="First Name"
                    defaultValue={applicant?.firstName}
                    name={"firstName"}
                    isWider
                  />
                  <UpdatableText
                    title="Last Name"
                    defaultValue={applicant?.lastName}
                    name={"lastName"}
                    isWider
                  />
                  <UpdatableText
                    title="Middle Name"
                    defaultValue={applicant?.middleName}
                    name={"middleName"}
                    isWider
                  />
                  <UpdatableText
                    title="Social Media Permission"
                    defaultValue={applicant?.socialMediaPermission ? "Yes" : "No"}
                    name={"socialMediaPermission"}
                    isWider
                  />
                </div>
                <div className="profile-ud-item">
                  <UpdatableText
                    title="Region"
                    defaultValue={applicant?.region}
                    name={"region"}
                    isWider
                  />
                  <UpdatableText
                    title="District / City"
                    defaultValue={applicant?.city}
                    name={"city"}
                    isWider
                  />
                   <UpdatableText
                    title="School/Academic Lyceum"
                    defaultValue={applicant?.school}
                    name={"school"}
                    isWider
                  />
                  <UpdatableText
                    title="IELTS Score"
                    defaultValue={applicant?.ieltsScore ?? "-"}
                    name={"ieltsScore"}
                    isWider
                  />
                </div>
                <div className="profile-ud-item">
                <UpdatableText
                    title="Email Address"
                    defaultValue={applicant?.email}
                    name={"email"}
                    isWider
                  />
                  <UpdatableText
                    title="Phone Number"
                    defaultValue={applicant?.phoneNumber}
                    name={"phoneNumber"}
                    isWider
                  />
                    <UpdatableText
                    title="Secondary Phone Number"
                    defaultValue={applicant?.secondPhoneNumber}
                    name={"secondaryPhoneNumber"}
                    isWider
                  />
                </div>
              </div>
            </form>
          </Block>
        </Card>
      </Content>
    </>
  );
};

export default OlimpiadParticipantDetailsPage;
