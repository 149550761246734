import React, { useState } from "react";
import remove from "lodash/remove";

import { OlympiadPageContext } from "../contexts/olympiadPageContext";
import { useEventListener } from "../hooks/useEventListener";
import { EVENT_TOPICS } from "../utilities/eventUtils";

const OlympiadPageContextProvider = ({ children }) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [totalPages, setTotalPages] = useState();
  const [applicants, setApplicants] = useState();
  const [applicant, setApplicant] = useState();

  useEventListener(
    EVENT_TOPICS.ON_APPLICANT_DELETED,
    ({ deletedApplicantId }) => {
      const copiedData = [...applicants];
      remove(copiedData, (item) => item.id === deletedApplicantId);

      setApplicants(copiedData);
    },
    [applicants]
  );

  useEventListener(
    EVENT_TOPICS.ON_APPLICANT_UPDATED,
    ({ response }) => {
      setApplicant(response);
    },
    [applicant]
  );

  return (
    <OlympiadPageContext.Provider
      value={{
        applicant,
        setApplicant,
        applicants,
        setApplicants,
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        totalRecords,
        setTotalRecords,
        pageSize,
        setPageSize,
      }}
    >
      {children}
      </OlympiadPageContext.Provider>
  );
};

export default OlympiadPageContextProvider;
