import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import {
  getOlympiadParticipantById,
  getOlympiadParticipants,
  removeOlympiadParticipant,
  updateOlympiadParticipantStatus,
} from "../repositories/olympiadRepository";

export const olympiadService = {
  getOlympiadParticipants: async ({ currentPage, pageSize }) => {
    const pageNumber =
      isUndefined(currentPage) || isNull(currentPage) ? 1 : currentPage;
    const itemsPerPage =
      isUndefined(pageSize) || isNull(pageSize) ? 20 : pageSize;

    return await getOlympiadParticipants({
      page: Number(pageNumber) - 1,
      size: itemsPerPage,
    });
  },

  getOlympiadParticipantById: async (id) =>
    await getOlympiadParticipantById(id),

  updateOlympiadParticipantStatus: async (status, body) =>
    await updateOlympiadParticipantStatus(status, body),

  removeOlympiadParticipant: async (id) => await removeOlympiadParticipant(id),
};
